(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/personalized-content/assets/javascripts/use-personalized-content-allowed.js') >= 0) return;  svs.modules.push('/components/oddset/personalized-content/assets/javascripts/use-personalized-content-allowed.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;

const {
  useState,
  useEffect
} = React;
const logger = new svs.core.log.Logger('components:oddset:personalized-content-allowed:use-personalized-content-allowed');
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;

const usePersonalizedContentAllowed = () => {
  const [isPersonalizedContentAllowed, setIsPersonalizedContentAllowed] = useState(false);
  useEffect(() => {
    try {
      const getRelevantContent = async () => {
        var _customizedSettingsRe;
        const customizedSettingsRes = await svs.core.jupiter.callAsync({
          method: 'GET',
          path: '/player/1/customizedsettings'
        });
        setIsPersonalizedContentAllowed(Boolean(customizedSettingsRes === null || customizedSettingsRes === void 0 || (_customizedSettingsRe = customizedSettingsRes.response) === null || _customizedSettingsRe === void 0 || (_customizedSettingsRe = _customizedSettingsRe.customizedSettings) === null || _customizedSettingsRe === void 0 || (_customizedSettingsRe = _customizedSettingsRe.properties) === null || _customizedSettingsRe === void 0 ? void 0 : _customizedSettingsRe.relevantContent));
      };
      const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
      if (isLoggedIn && !isSelfService) {
        getRelevantContent();
      }
    } catch (error) {
      logger.warn('Error fetching user preference for relevant content', error);
    }
  }, []);
  return {
    isPersonalizedContentAllowed
  };
};
setGlobal('svs.components.oddset.personalizedContent', {
  usePersonalizedContentAllowed
});

 })(window);